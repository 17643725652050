import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import {TranslateModule} from "@ngx-translate/core";
import { TicketsBuySectionComponent } from './tickets-buy-section.component';
import { ElementTileComponentModule } from 'src/app/components/element-tile/element-tile.module';
import { QuantityInputComponentModule } from 'src/app/components/quantity-input/quantity-input.component.model';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        RouterModule,
        TranslateModule,
        ElementTileComponentModule,
        QuantityInputComponentModule
    ],
  declarations: [TicketsBuySectionComponent],
  exports: [
    TicketsBuySectionComponent
  ]
})
export class TicketsBuySectionComponentModule {}
