import { CommonModule } from '@angular/common';
import { Component, Input, type OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { CustomFieldsFormComponent } from 'src/app/components/custom-fields-form/custom-fields-form.component';
import { CustomFieldDTO } from 'src/app/models/CustomFieldDTO';
import { TicketDTO } from '../../DTO/TicketDTO';
import { UserModel } from 'src/app/models/UserModel';
import { AuthService } from 'src/app/auth/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ticket-form',
    standalone: true,
    imports: [         
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        FormsModule,        
        CustomFieldsFormComponent,
        IonicModule
    ],
    templateUrl: './ticket-form.component.html',
    styleUrls: ['./ticket-form.component.scss'],    
})
export class TicketFormComponent implements OnInit {

    @Input() ticket: TicketDTO;
    @Input() price: number;
    
    public customFields: CustomFieldDTO[] = [];
    public formGroup: FormGroup;
    public submitAttempted: boolean = false;    
    public user: UserModel = null;
    public isLogged = false;
    public finalPrice: number;
    public corpColor: string;

    constructor(private modalCtrl: ModalController,
        public formBuilder: FormBuilder,
        private auth: AuthService,
        private storage: StorageService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void { 
        this.init();
    }

    public checkForm() {
        this.submitAttempted = true;

        if(this.formGroup.valid) {
            this.continue();
        } else {
            Swal.fire({                
                title: this.translate.instant('Oops'),
                icon: 'warning',
                text: this.translate.instant('CheckFormFields'),
                confirmButtonText: this.translate.instant('CONTINUE')
            });
        }
    }

    public cancel() {
        return this.modalCtrl.dismiss(null, 'cancel');
    }
    
    private continue() {        
        return this.modalCtrl.dismiss(null, 'confirm');
    }

    private async init() {
        const community = await this.storage.GetTown();
        this.corpColor = community.color1;
        
        this.customFields = this.ticket.customFields;
        this.isLogged = await this.auth.isAuthenticated();

        if(this.isLogged) {
            this.user = await this.auth.getUser();
        }

        this.formGroup = this.formBuilder.group({});        
    }
}
