import { Pipe, PipeTransform } from '@angular/core';
import { IMG_URL, STORAGE_URL } from 'src/environments/environment';

@Pipe({
  name: 'imageUrl'
})
export class ImageUrlPipe implements PipeTransform {

  transform(value: string, type?: string, isFile: boolean = false): string {
    const url = isFile ? STORAGE_URL : IMG_URL;
    if (!value || value.startsWith('http')) {
      return value;
    } else if (value.startsWith('/')) {
        return IMG_URL + value;
    } else {
        return IMG_URL + `/${type}/` +  value;
    }
  }

}
