import { AuthService } from './../auth/auth.service';
import { ApiService } from 'src/app/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/ca';
import 'moment/locale/fr';
import { CommunityService, ParamKeys } from './community/community.service';
import { Settings } from 'luxon';
import { GoogleObj } from '../models/GoogleObj';
import { GOOGLE_TRANSLATE_KEY } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { combineLatest } from 'rxjs';

const LNG_KEY = 'LANGUAGE';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public selected = '';

  private all_languages = [
    {
      key: 'ca',
      text: 'Català'
    },
    {
      key: 'es',
      text: 'Español'
    },
    {
      key: 'en',
      text: 'English'
    },
    {
      key: 'fr',
      text: 'Français'
    }
  ];
  public available_languages : any [];

  googleUrl = 'https://translation.googleapis.com/language/translate/v2';
  googleKey = GOOGLE_TRANSLATE_KEY;
 
  constructor(
    private translate: TranslateService, 
    private storage: Storage,
    private api: ApiService,
    private auth: AuthService,
    private communityService: CommunityService,
    private http: HttpClient
    ) {
      this.available_languages = this.all_languages;
    }
 
  setInitialAppLanguage() {
    let language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);
 
    this.storage.get(LNG_KEY).then(val => {
      if (val) {
        this.setLanguage(val);
        this.selected = val;
      }else{
        this.setLanguage(language);
        //this.setLanguage('ca');
      }
    });
    
    combineLatest([
      this.communityService.GetParameters(),
      this.communityService.Get()
    ]).subscribe(([parameters, community]) => {
      if (parameters[ParamKeys.LANGUAGES]) {
        let langs = parameters[ParamKeys.LANGUAGES].split(',');            

        if (community?.code === 'pedreguer') {
          this.available_languages.map(x => {
            if (x.key == 'ca') {
              x.text = 'Valencià';
            }
          })              
        }

        this.available_languages = this.all_languages.filter(x => langs.includes(x.key));
        this.setLanguage(langs[0]);
      } else {
        this.available_languages = this.all_languages;
      }
    });
  }
 
  setLanguage(lng: string) {
    this.translate.use(lng);
    this.selected = lng;    
    this.storage.set(LNG_KEY, lng);
    moment.locale(lng);
    Settings.defaultLocale = lng;

    this.auth.isAuthenticated().then((res) => {
      if(res){
        this.api.User_setLang(lng);
      }
    });
  }

  getLanguage(){
    return this.selected;
  }

  googleTranslate(textToTranslate: string[], format: string) {         
    const googleObj: GoogleObj = {
      q: textToTranslate,
      target: this.getLanguage(),
      format
    };    
    return this.http.post(`${this.googleUrl}?key=${this.googleKey}`, googleObj);
  }  
}
