<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button color="medium" (click)="cancel()">{{ 'Cancel' | translate }}</ion-button>
        </ion-buttons>
        <ion-title>{{ 'Payment' | translate }}</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ng-container>
        <ng-container *ngIf="MODE == 'REDIRECTION' && iframeUrl">
            <iframe #iframeElement [src]="iframeUrl | safe:'url'" frameborder="0"></iframe>
        </ng-container>
    
        <ng-container *ngIf="MODE == 'INSITE_UNIFIED'">
            <div id="card-form"></div>
        </ng-container>
    
        <ng-container *ngIf="MODE == 'INSITE_FIELDS'">
            <div class="cardinfo-card-number">
                <label class="cardinfo-label" for="card-number">Numero de tarjeta</label>
                <div class='input-wrapper' id="card-number"></div>
            </div>
            <div class="cardinfo-exp-date">
                <label class="cardinfo-label" for="card-expiration">Caducidad</label>
                <div class='input-wrapper' id="card-expiration"></div>
            </div>
            <div class="cardinfo-cvv">
                <label class="cardinfo-label" for="cvv">CVV</label>
                <div class='input-wrapper' id="cvv"></div>
            </div>
            
            <div id="boton"></div>
        </ng-container>
    
        <input type="hidden" id="token" />
        <input type="hidden" id="errorCode" />
    </ng-container>
					                         
</ion-content>