import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { App } from '@capacitor/app';
import { Platform } from '@ionic/angular';
import { combineLatest, from, Observable, ReplaySubject, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { TownModel } from 'src/app/models/TownModel';
import { CommunityType } from 'src/constants';
import { ThemeService } from '../theme/theme.service';
import { environment } from 'src/environments/environment';
import * as CUSTOM_APPS_IDS from 'src/custom-apps-ids.json';
import * as Sentry from "@sentry/angular-ivy";

export class ParamKeys {
  public static readonly LANGUAGES = 'LANGUAGES';
  public static readonly APP_LOGIN_REQUIRED = 'APP.LOGIN_REQUIRED';
  public static readonly APP_DISABLE_REGISTER = 'APP.DISABLE_REGISTER';
  
}

@Injectable({
  providedIn: 'root'
})
export class CommunityService {
  
  private _community: ReplaySubject<TownModel> = new ReplaySubject(1);

  constructor(
    @Inject(DOCUMENT) private document: any,
    private platform: Platform,
    private theme: ThemeService,
  ) { }

  public Get(): Observable<TownModel>{
    return this._community.asObservable();
  }

  public async Set(c: TownModel){
    console.log("CommunityService set", c);
    if(c) {
      this.theme.setTheme(c);
      Sentry.setTag("community", c.code);
    }
    this._community.next(c);
    
  }

  public GetCommunityType(): Observable<CommunityType>{
    var communityObs = this.Get();
    var appIDTypeObs = from(this.GetAppIdDefaultCommunityType());
    return combineLatest([communityObs, appIDTypeObs])
      .pipe(
        map(x => x[0]?.communityType ?? x[1])
      );
  }

  public IsWecoo(): Observable<boolean>{
    return this.GetCommunityType().pipe(
        map(x => x != CommunityType.Town)
      );
  }

  public IsWeTown(): Observable<boolean>{
    return this.GetCommunityType().pipe(
        map(x => x == CommunityType.Town)
      );
  }

  public IsGenericApp(): Observable<boolean>{
    const observable = from(this.GetAppIdDefaultCommunityCode());
    return observable.pipe(
        map(x => x == null)
      );
  }

  public async GetAppIdDefaultCommunityCode(): Promise<string | null>{
    if (this.platform.is('capacitor')) {
      const app = await App.getInfo();

      let found = Object.entries(CUSTOM_APPS_IDS).find(([key, value]) => app.id.startsWith(key));
      if (found) return found[1];
    }

    // forceAppId for TEST
    const FORCE_APP_ID = null;
    if (FORCE_APP_ID){
      let found = Object.entries(CUSTOM_APPS_IDS).find(([key, value]) => FORCE_APP_ID.startsWith(key));
      if (found) return found[1];
    }
    return null;
  }

  private async GetAppIdDefaultCommunityType(): Promise<CommunityType>{
    if (this.platform.is('capacitor')) {
      const app = await App.getInfo();
  
      if(app.id.includes('wetown')){
        return CommunityType.Town;
      }
    } else {
      let hostname = this.document.location.hostname as string;
      if(hostname.includes('wetown')){
        return CommunityType.Town;
      }
    }

    return null;
  }

  public GetParameters(): Observable<{}>{
    return this.Get().pipe(
      map((c) => {
        if(!c || !c.parameters){
        return {};
        }

        //Map an array of objects to a dictionary
        let parameters = Object.assign({}, ...c.parameters.map((x: any) => ({[x.key]: x.value})));
        return parameters;
      })
    );
  }

  public GetParameter(paramKey :string): Observable<string>{
    return this.GetParameters().pipe(
      map((parameters) => {
        return parameters[paramKey];
      })
    );
  }

  private async GetShareBaseLink(): Promise<string>{
    let type = await this.GetCommunityType().pipe(first()).toPromise();

    if(type == CommunityType.Town){
      if(environment.production){
        return 'https://link.wetown.app'
      } else {
        return `https://link.${environment.tag}.wetown.app`;
      }
    } else {
      if(environment.production){
        return 'https://link.wecoo.app'
      } else {
        return `https://link.${environment.tag}.wecoo.app`;
      }
    }
  }

  public async MakeShareLink(path: string): Promise<string>{
    const c = await this.Get().pipe(first()).toPromise()
    const shareBaseLink = await this.GetShareBaseLink();

    return shareBaseLink + "/" + c.code + path;
  }

  public GetGenericAppName(): Observable<string> {
    return this.GetCommunityType()
      .pipe(
        map(x => (x == CommunityType.Town) ? "We<b>Town</b>" : "Wecoo")
      );
  }
  
}
