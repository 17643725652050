
<input #fileUpload type="file" class="file-input"
[accept]="requiredFileType"
(change)="onInputFilesChange($event)"
[multiple]="maxFiles > 1">

<div class="thumbs-wrapper">
    <div *ngFor="let file of files" class="thumb" [class.error]="file.error"   
        (drop)="dropHandler($event, file)"
        (dragenter)="dragEnterHandler($event, file)"
        (dragover)="dragOverHandler($event, file)"
        (dragleave)="dragLeaveHandler($event, file)"
        [class.is-dragover]="file.isDragover"
        >
        <ng-container *ngIf="file.isImage">
            <img *ngIf="file.path" [attr.data-fancybox]="'gallery-' + componentUuid" [src]="file.path | imageUrl" [alt]="'Image' | translate" [ngStyle]="{'object-fit': fit}">
        </ng-container>

        <ng-container *ngIf="!file.isImage">
            <div *ngIf="file.path" class="flex-center">
                <div>
                    <i class="fa fa-file"></i>
                </div>
                <div class="filename">
                    {{file.originalName}}
                </div>
            </div>
        </ng-container>

        <div *ngIf="file.path && autoUpload" class="overlay" (click)="show($event, file)">
            <i class="fa fa-eye"></i>
            <div class="remove-button" *ngIf="!viewOnly" (click)="removeConfirm($event, file)">
                <i class="fa fa-trash"></i>
            </div>
        </div>

        <div *ngIf="file.path && !autoUpload" class="overlay" (click)="removeConfirm($event, file)">
            <i class="fa fa-trash"></i>
        </div>

        <div *ngIf="file.error" class="overlay" (click)="remove(file)">
            <i class="fa fa-trash"></i>
        </div>

        <div *ngIf="file.uploadProgress != null" class="flex-center">
            <div class="circular-progress">
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' role='graphic'>
                    <circle cx="50" cy="50" r="40" ></circle>
                    <circle cx="50" cy="50" r="40" id='pct-ind' [style.strokeDashoffset]="file.dashoffset"></circle>
                </svg>
                <!--<p class="pct">{{uploadProgress}}%</p>-->
            </div>
        </div>

        <div *ngIf="file.error" class="flex-center">
            <i class="fa fa-exclamation"></i>
        </div>
    </div>

    <div *ngIf="(!files || files.length < maxFiles) && !viewOnly"
        id="drop_zone"
        (click)="onClick()"
        (drop)="dropHandler($event)"
        (dragenter)="dragEnterHandler($event)"
        (dragover)="dragOverHandler($event)"
        (dragleave)="dragLeaveHandler($event)"
        [class.is-dragover]="dropZone_IsDragOver"
    >
    <ng-container>
        <ng-container *ngIf="type == Type.Image">
            <span *ngIf="maxFiles == 1">{{ 'DropZone.DROP_IMAGE_HERE' | translate }}</span>
            <span *ngIf="maxFiles > 1">{{ 'DropZone.DROP_IMAGES_HERE' | translate }}</span>
        </ng-container>
        <ng-container *ngIf="type == Type.File">
            <span *ngIf="maxFiles == 1">{{ 'DropZone.DROP_FILE_HERE' | translate }}</span>
            <span *ngIf="maxFiles > 1">{{ 'DropZone.DROP_FILES_HERE' | translate }}</span>
        </ng-container>

        <div>
            <i class="fa fa-plus"></i>
        </div>
        {{ 'DropZone.OR_CLICK_ON_AREA' | translate | lowercase }}
    </ng-container>
</div>
</div>
