import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  forwardRef,
  OnDestroy,
  ChangeDetectorRef,
  ApplicationInitStatus
} from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { API_URL, STORAGE_URL } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ApplicationPipesModule } from 'src/app/pipes/application-pipes.module';
import { StorageService } from 'src/app/services/storage.service';
import { UploadedFileModel } from 'src/app/models/helpers/UploadedFileModel';

export enum Type {
  Image,
  File
}

enum ApiVersion {
  v1 = 'v1',
  v2 = 'v2'
}

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ApplicationPipesModule,
    TranslateModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploaderComponent),
      multi: true
    }
  ]
})
export class FileUploaderComponent implements OnInit, OnDestroy, ControlValueAccessor {
  /**
   * Component for file upload.
   * 
   * 20/08/2024 viewOnly
   * 22/04/2024 Open tab with filename
   * 17/03/2024 replaceFile fix
   * 27/02/2024 blobFile input
   * 10/01/2024 isImage if extension detected
   * ...
   * 
  */

  public static readonly Type = Type;

  private static readonly IMAGE_EXTTENSIONS = ['.jpg', '.jpeg', '.png', '.webp']
  private headers: HttpHeaders = new HttpHeaders();

  @Input()
  maxFiles = 1;

  @Input()
  requiredFileType: string;

  @Input()
  viewOnly = false;

  @Input()
  events: EventEmitter<string>;

  @Input()
  type: Type = Type.Image;

  @Input()
  category: string;

  @Input()
  fit = 'cover';

  @Input()
  files: UploadedFileModel[] = [];

  @Input()
  apiVersion: ApiVersion | keyof typeof ApiVersion = ApiVersion.v2;

  @Input()
  autoUpload = true;

  @Input()
  modelProp: string | null = null;

  @Output()
  filesChange = new EventEmitter<UploadedFileModel[]>();
  
  @Output() 
  onSelect = new EventEmitter<FileList | null>();

  @ViewChild('fileUpload') fileUpload: ElementRef;

  public componentUuid: string;

  public Type = Type;

  private eventsSubscription: Subscription;

  dropZone_IsDragOver = false;

  propagateChangeOut = (_: any) => {};

  constructor(
    private http: HttpClient,
    private _traslate: TranslateService,
    private _changesDetected: ChangeDetectorRef,
    private storage: StorageService
  ) {
  }

  writeValue(value: string): void {
    if (value !== undefined) {
      if (value) {
        if(Array.isArray(value)){
          value.forEach(x => {
            x.isImage = FileUploaderComponent.IMAGE_EXTTENSIONS.some(y => x.path.toLowerCase().includes(y));
          });
          this.files = value;
        } else {
          this.files = [this.mapFromProp(value)];
        }
      } else {
        this.files = [];
      }
    }
  }
  registerOnChange(fn: any): void {
    this.propagateChangeOut = fn;
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }

  propagateChange() {
    this.filesChange.emit(this.files);

    if (this.maxFiles === 1) {

      if (!this.files.length) {
        this.propagateChangeOut(null);
        return;
      }

      this.propagateChangeOut(this.mapToProp(this.files[0]));
      
    } else {
      this.propagateChangeOut(this.files.map(x => this.mapToProp(x)));
    }
  }

  ngOnInit() {

    this.componentUuid = (Math.random() * 9999).toString();

    if (this.events) {
      this.eventsSubscription = this.events.subscribe((event) => {
        switch (event) {
          case 'open':
            this.fileUpload.nativeElement.click();
            break;
          case 'reset':
            console.log("entroReset");
            this.files = [];
            this.files.slice(0 , this.files.length);
            this._changesDetected.detectChanges();
            this.propagateChange();
            (<HTMLInputElement>this.fileUpload.nativeElement).value = '';
        }
      });
    }

    /*if (this._initialFiles) {
      this.files = this._initialFiles.map(x => ({ path: x }) as UploadedFileModel);
    }*/
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

  onInputFilesChange(event: any) {
    let target = event.target as HTMLInputElement;

    this.onSelect.emit(target.files);

    if (target.files){
      for (let index = 0; index < event.target.files.length; index++) {
        const file = target.files[index] as File;
        const model = this.newFileModelAndPush();

        if (this.autoUpload){
          this.uploadFileOrImage(file, model);
        } else {
          model.filename = file.name;
          model.path = file.name;
        }
      }
    }
    
    if (this.autoUpload){
      this.resetInputHTML();
    }
    
  }

  uploadFileOrImage(file: File, model: UploadedFileModel, replaceFile = null) {

    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      model.isImage = FileUploaderComponent.IMAGE_EXTTENSIONS.some(y => file.name.toLowerCase().includes(y));   

      if (!this.files) { this.files = []; }

      var callback = () => {
        if (model.isImage || this.type === Type.Image) {

          if (this.apiVersion == ApiVersion.v2) {
            this.uploadImageV2(model, formData);
          } else {
            this.uploadImage(model, formData);
          }
        } else if (!model.isImage || this.type === Type.File) {
          this.uploadFile(model, formData);
        }
      };

      let replaceIndex = replaceFile ? this.files.indexOf(replaceFile) : null;

      if (replaceIndex != null && replaceIndex != -1) {
        Swal.fire({
          reverseButtons: true,
          title: this._traslate.instant('AreYouSure'),
          html: this._traslate.instant('DropZone.FileWillBeReplaced'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#ef5350',
          //cancelButtonColor: '#2f4050',
          confirmButtonText: this._traslate.instant('YesContinue'),
          cancelButtonText: this._traslate.instant('CANCEL'),
        }).then((result) => {
          if (result.isConfirmed) {
            if (replaceIndex != null){
              this.files[replaceIndex] = model;
            }
            callback();
          }
        });
      }else{
        callback();
      }

    }
  }

  private uploadImage(model: UploadedFileModel, formData: FormData) {
    if (model) {
      const upload$ = this.http.post(`${API_URL}/Files/uploadImage?category=${this.category ?? ''}`, formData, {
        headers: this.headers,
        reportProgress: true,
        observe: 'events',
        responseType: 'text'
      })
      .pipe(
          finalize(() => model.reset()),
      );

      model.updateProgress(0);

      model.uploadSub = upload$.subscribe(uploadEvent => {
        if (uploadEvent.type === HttpEventType.UploadProgress) {
          model.updateProgress(uploadEvent.loaded / (uploadEvent.total ?? 0));
        } else if (uploadEvent instanceof HttpResponse) {
          model.path = uploadEvent.body ?? "";

          this.propagateChange();
        }
      }, (err) => { model.error = err; });
    }
  }

  private uploadImageV2(model: UploadedFileModel, formData: FormData) {
    if (model) {
      const upload$ = this.http.post<any>(`${API_URL}/v2/Files/uploadImage?category=${this.category ?? ''}`, formData, {
        headers: this.headers,
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
          finalize(() => model.reset()),
      );

      model.updateProgress(0);

      model.uploadSub = upload$.subscribe(uploadEvent => {
        if (uploadEvent.type === HttpEventType.UploadProgress) {
          model.updateProgress(uploadEvent.loaded / (uploadEvent.total ?? 0));
        } else if (uploadEvent instanceof HttpResponse) {
          model.id = uploadEvent.body.id;
          model.path = uploadEvent.body.path;

          this.propagateChange();
        }
      }, (err) => { model.error = err; });
    }
  }

  private async uploadFile(model: UploadedFileModel, formData: FormData) {
    const { id } = await this.storage.GetTown();

    if (model) {
      const upload$ = this.http.post<any>(`${API_URL}/Files/uploadFile?townId=${id}${this.category ? `&category=${this.category}` : ''}`, formData, {
        headers: this.headers,
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
          finalize(() => model.reset()),
      );

      model.updateProgress(0);

      model.uploadSub = upload$.subscribe(uploadEvent => {
        if (uploadEvent.type === HttpEventType.UploadProgress) {
          model.updateProgress(uploadEvent.loaded / (uploadEvent.total ?? 0));
        } else if (uploadEvent instanceof HttpResponse) {
          model.id = uploadEvent.body.id;
          model.path = uploadEvent.body.path;
          model.filename = uploadEvent.body.filename;
          model.originalName = uploadEvent.body.originalName;
          model.isImage = FileUploaderComponent.IMAGE_EXTTENSIONS.some(x => model.path.toLowerCase().includes(x));

          this.propagateChange();
        }
      }, (err) => { model.error = err; });
    }
  }

  async show(event: any, file: UploadedFileModel) {
    if (file.isImage || this.type === Type.Image) {
      event.currentTarget?.parentElement?.querySelector('img')?.click();
    } else if(this.type === Type.File){
      if (file.filename){
        window.open(`${API_URL}/Files/get/${file.filename}?path=${file.path}`, '_blank');
      } else {
        window.open( STORAGE_URL + file.path, '_blank');
      }
    }
  }

  async removeConfirm(event: any, file: UploadedFileModel) {

    event.stopPropagation();  

    Swal.fire({
      reverseButtons: true,
      title: this._traslate.instant('AreYouSure'),
      html: this._traslate.instant('DropZone.FileWillBeRemoved'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      //cancelButtonColor: '#2f4050',
      confirmButtonText: this._traslate.instant('YesDelete'),
      cancelButtonText: this._traslate.instant('CANCEL'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.remove(file);
      }
    });
  }

  remove(file: UploadedFileModel) {
      const index = this.files.indexOf(file, 0);
      if (index > -1) {
        this.files.splice(index, 1);
      }


    this.propagateChange();
    this.resetInputHTML();
  }

  onClick() {
    this.fileUpload.nativeElement.click();
  }

  dropHandler(ev: any, replaceFile: any = null) {
    if (this.viewOnly) {
      return;
    }
    this.setDragover(false, replaceFile);

    // Evitar el comportamiendo por defecto (Evitar que el fichero se abra/ejecute)
    ev.preventDefault();

    if (!this.files) { this.files = []; }

    if (!replaceFile && (this.files.length + this.filesCount(ev)) > this.maxFiles) {
      return;
    }

    let uploadOrAddCallback = (file: any)=>{
      let model = this.newFileModelAndPush(replaceFile != null);
      if (this.autoUpload){
        this.uploadFileOrImage(file, model, replaceFile);
      } else {
        model.filename = file.name;
        model.path = file.name;
      }
    };
    
    var files = [];
    if (ev.dataTransfer.items) {
      // Usar la interfaz DataTransferItemList para acceder a el/los archivos)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // Si los elementos arrastrados no son ficheros, rechazarlos
        if (ev.dataTransfer.items[i].kind === 'file') {
          const file = ev.dataTransfer.items[i].getAsFile();
          uploadOrAddCallback(file);
          files.push(file);
        }
      }
    } else {
      // Usar la interfaz DataTransfer para acceder a el/los archivos
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        const file = ev.dataTransfer.files[i];
        uploadOrAddCallback(file);
        files.push(file);
      }
    }
    this.onSelect.emit(files as any);

    // Pasar el evento a removeDragData para limpiar
    this.removeDragData(ev);
  }

  dragEnterHandler(ev: any, file: any = null) {
    if (this.viewOnly) {
      return;
    }
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    this.setDragover(true, file);
  }

  dragOverHandler(ev: any, file: any = null) {
    if (this.viewOnly) {
      return;
    }
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    this.setDragover(true, file);

    if (this.filesCount(ev) > this.maxFiles) {
      ev.dataTransfer.dropEffect = 'none';
    }
  }

  dragLeaveHandler(ev: any, file: any = null) {
    if (this.viewOnly) {
      return;
    }
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    this.setDragover(false, file);
  }

  private setDragover(state: boolean, file: any = null){
    if(file) {
      file.isDragover = state;
      this.dropZone_IsDragOver = false;
    } else {
      this.dropZone_IsDragOver = state;
    }
  }

  private newFileModelAndPush(isReplace = false): UploadedFileModel {
    const model = new UploadedFileModel();
    
    if (!this.files) { this.files = []; }

    if (!isReplace){
      this.files.push(model);
    }

    this.propagateChange();

    return model;
  }

  private inputHTML(): HTMLInputElement{
    return <HTMLInputElement>this.fileUpload.nativeElement;
  }

  private resetInputHTML(){
    this.inputHTML().value = '';
  }

  private filesCount(ev: any) {
    if (ev.dataTransfer.items) {
      return ev.dataTransfer.items.length;
    } else {
      return ev.dataTransfer.files.length;
    }
  }

  private removeDragData(ev: any) {
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to remove the drag data
      ev.dataTransfer.items.clear();
    } else {
      // Use DataTransfer interface to remove the drag data
      ev.dataTransfer.clearData();
    }
  }

  private mapToProp(model: UploadedFileModel){
    switch (this.modelProp) {
      case 'path':
        return model.path;
      default:
        return model;
    }
  }

  private mapFromProp(prop: any){
    let model: UploadedFileModel;
    switch (this.modelProp) {
      case 'path':
        model = {path: prop} as UploadedFileModel;
        break
      default:
        model = prop;
    }

    model.isImage = FileUploaderComponent.IMAGE_EXTTENSIONS.some(x => model.path.toLowerCase().includes(x));

    return model;
  }

}
