import { Injectable } from '@angular/core';
import { PaymentSheetEventsEnum, Stripe } from '@capacitor-community/stripe';
import { Capacitor } from '@capacitor/core';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { TranslateService } from '@ngx-translate/core';
import { PaymentStatus, PurchaseResultDTO } from 'src/app/models/payments/PurchaseResultDTO';
import { API_URL } from 'src/environments/environment';
import { RedsysModalComponent } from './RedsysModal/RedsysModal.component';
import { ApiService } from '../api.service';
import { CommunityService } from '../community/community.service';
import { first } from 'rxjs/operators';

export enum PaymentResult{
  SUCCESS,
  FAILED,
  CANCEL
}

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private actionSheetCtrl: ActionSheetController,
    private api: ApiService,
    private communityService: CommunityService
  ) { }

  public async processPurchaseResult(res: PurchaseResultDTO): Promise<PaymentResult>{
    if (res?.status === 'PAID') {
      //this.isPurchaseLoading = false;
      //this.reload();
      return PaymentResult.SUCCESS;
    } else if(res?.status === 'PENDING'){
      if (res?.method === 'STRIPE') {
        return await this.stripePayment(res);
      } else if (res?.method === 'REDSYS') {
        //this.isPurchaseLoading = false;
        let redsysData = res?.redsysData;
        return await this.redsysPayment(res.purchaseUuid, redsysData.fuc, redsysData.terminal, redsysData.orderId);
      } else {
        return PaymentResult.FAILED;
      }
    } else {
      return PaymentResult.FAILED;
    }
  }

  private async stripePayment(res: PurchaseResultDTO): Promise<PaymentResult>{

    if (!Capacitor.isNativePlatform()){
      Stripe.addListener(PaymentSheetEventsEnum.Loaded, () => {
        //this.isPurchaseLoading = false;
      });
    }
    
    await Stripe.createPaymentSheet({
      paymentIntentClientSecret: res.stripeData.paymentIntent,
      customerId: res.stripeData.customerId,
      customerEphemeralKeySecret: res.stripeData.ephemeralKey,
      merchantDisplayName: 'Wecoo',
      withZipCode: false
    });

    var result = await Stripe.presentPaymentSheet();

    if (result.paymentResult === PaymentSheetEventsEnum.Completed) {
      //this.isPurchaseLoading = true;
      
      try {
        let c = await this.communityService.Get().pipe(first()).toPromise();
        let checkResult = await this.api.checkPayment(c.code, res.purchaseUuid);

        //this.isPurchaseLoading = false;

        if (checkResult.status == 'PAID'){
          //this.reload();
          //this.purchaseSuccessMessage();
          return PaymentResult.SUCCESS;
        } else {
          //this.purchaseFailedMessage(res.error.message);
          return PaymentResult.FAILED;
        }
      } catch (error) {
        //this.purchaseFailedMessage();
        return PaymentResult.FAILED;
      }

    } else {
      //this.purchaseFailedMessage();
      return PaymentResult.FAILED;
    }
    
  }

  private async redsysPayment(purchaseUuid: string, fuc: string, terminal: string, orderId: string): Promise<PaymentResult> {
    
    let connection = new HubConnectionBuilder()
    .withUrl(API_URL + '/hubs/payments', {
      withCredentials: false
    })
    .build();
    
     // Modal
     const modal = await this.modalCtrl.create({
      component: RedsysModalComponent,
      componentProps: {
        fuc,
        terminal,
        orderId,
        purchaseUuid,
        //ticketId: this.ticketId
      }
    });

    // On payment result
    connection.on("payment-result", (res: PurchaseResultDTO ) => {
      if (res.status == PaymentStatus.PAID){
        modal.dismiss(null, 'ok');
        //this.reload();
        //this.purchaseSuccessMessage();
      } else if (res.status == PaymentStatus.ERROR){
        modal.dismiss(null, 'error');
        //this.purchaseFailedMessage(res.error?.message);
      } else {
        console.warn("payment-result: " + res.status);
      }
    });

    // Connection stat
    connection.start()
    .then(_ => {
      console.log('Connection Started');
      connection.invoke('WaitForPayment', purchaseUuid);

    }).catch(error => {
      console.error(error);
    });

    // Modal present

    modal.present();

    const { role } = await modal.onWillDismiss();  
    
    connection.stop();


    if (role === 'ok') {
      return PaymentResult.SUCCESS;
    } else if (role === 'error'){
      return PaymentResult.FAILED;
    } else {
      return PaymentResult.CANCEL;
    }
  }
  
  /*
  private purchaseSuccessMessage(){
    this.isPurchaseLoading = false;

    Swal.fire({
      title: this.translate.instant('GOOD'),
      text: this.translate.instant('Modules.Ticketing.TICKETS_BUY_SUCCESS'),
      icon: 'success',
      //confirmButtonText: t['ACCEPT'],
    });
  }

  private async purchaseFailedMessage(message?: string){
    this.isPurchaseLoading = false;

    Swal.fire({
      title: this.translate.instant('Oops'),
      text: message || (this.translate.instant('Modules.Ticketing.ErrorPayment')),
      icon: 'error',
      confirmButtonText: this.translate.instant('ACCEPT'),
    });
  }
  */

  public async presentByPassPaymentForDemoActionSheet() {
    return new Promise<any>(async (resolve, reject) => {
      const actionSheet = await this.actionSheetCtrl.create({
        mode: 'ios',
        header: 'Demo',
        buttons: [
          {
            text: 'Simular pagament correcte',
            data: {
              byPass: true,
            },
          },
          {
            text: 'Pasarel·la de pagament',
            data: {
              byPass: false,
            },
          },
          {
            text: this.translate.instant('CANCEL'),
            role: 'cancel'
          },
        ],
      });
  
      actionSheet.onDidDismiss().then((result: any) => {
        resolve(result)
      })

      await actionSheet.present();   
    });
  }
}
