import { Component, Input, OnInit } from '@angular/core';
import { AttachedFileDTO } from 'src/app/models/AttachedFileDTO';

import prettyBytes from 'pretty-bytes';
import { FilesService } from 'src/app/services/files/files.service';
import { STORAGE_URL } from 'src/environments/environment';

@Component({
  selector: 'app-attached-files',
  templateUrl: './attached-files.component.html',
  styleUrls: ['./attached-files.component.scss'],
})
export class AttachedFilesComponent implements OnInit {

  prettyBytes = prettyBytes;

  @Input() 
  files: AttachedFileDTO[];

  constructor(
    private filesService: FilesService
  ) { }

  ngOnInit() {

    if (this.files){
      this.files.forEach(f => {
        const name = f.path.substring(f.path.lastIndexOf('/') + 1);
        this.filesService.fileExists(name).then((exists) => {
          f.inDisk = exists;
        });
        
      });
    }

  }

  openFile(file: AttachedFileDTO){
    file.isLoading = true;
    this.filesService.checkDownloadAndOpenFile(STORAGE_URL + file.path)
      .then((downloaded) => { 
        file.inDisk = downloaded;
      })
      .finally(() => {
        file.isLoading = false;
      });
  }

}
