import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";

export class Errors {

    public static showGenericError(translate: TranslateService) {
        Swal.fire({
            title: translate.instant('Errors.Generic.Title'),
            html: translate.instant('Errors.Generic.Text'),
            icon: 'error',
            confirmButtonText: translate.instant('ACCEPT'),
            customClass: {
                title: 'long-title'
            }
          });
    }
    
}