
export const STORAGE_PRESENTATION = 'presentation_done';
export const STORAGE_TUTORIAL = 'tutorial_done';
export const STORAGE_TOWN = 'town';
export const STORAGE_TOWNS_REQUESTED = 'towns_requested';


export class Constants {

    public static ModulesKeys = class {
        public static readonly INCIDENTS = 'INCIDENTS';
    }

    public static Parameters = class {
        public static readonly INCIDENTS_BANNER_MESSAGE = 'INCIDENTS.BANNER_MESSAGE';
    }

    public static Colors = class {
        public static readonly IS_LIGHT_BRIGHTNESS_THRESHOLD =  0.78;
    }

}

export enum CommunityType {
    Town = 0,
    Association = 1,
}

export enum ProposalStatus {
    Borrador = 0,

    PendienteAnalisisSPAM = 5,
    PossibleSPAM = -5,
    SPAM = -10,
    Bloqueada = -15,

    EnProcesoVotacion = 20,
    Caducada = 25,

    EnProcesoValoracion = 30,
    Aprobada = 40,
    Denegada = 45,

    EnEspera = 50
}

export enum ConsultationStatus {
    DRAFT = 0,
    SCHEDULED = 3,
    ACTIVE = 5,
    CLOSED = 10,
    CANCELLED = 15,
    DELETED = 20
}

export enum ImageCategory {
    Notification = 0,
    Proposal = 1,
    Consultation = 2,
    User = 3,
    Alerts = 4,
    MailBox = 5,
}

export enum ReactionType {
    COMMENT = 0,
    NEW = 1,
    NOTIFICATION = 2,
    Announcement = 3,
    Event = 4
}

export namespace CustomFieldsConstants {
    export enum InputTypes {
        TEXT = "TEXT",
        TEXTAREA = "TEXTAREA",
        WYSIWYG = "WYSIWYG",
        NUMERIC = "NUMERIC",
        DATE = "DATE",
        DATETIME = "DATETIME",
        CHECKBOX = "CHECKBOX",
        DROPDOWN = "DROPDOWN",
        IMG = "IMG",
        FILE = "FILE",
        IBAN = "IBAN",
        PHONE = "PHONE",
        DNI = "DNI"
    };
    
    export enum DropdownTypes {
        CHIPS = "CHIPS",
        TABLE = "TABLE"
    };

    export enum Permissions {
        READ_WRITE = 'rw'
    }
}

export class InscriptionsConstants {
    public static OpenStatuses = {
        OPEN: "OPEN",
        FUTURE: "FUTURE",
        PAST: "PAST",
    };
    public static RecurrencesTypes = {
        OneTime: "OneTime",
        Monthly: "Monthly",
        Quarterly: "Quarterly",
        Yearly: "Yearly"
    };
}

export namespace FileConstants {
    export enum Type {
        Image,
        File
    }
}