<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button color="medium" (click)="cancel()">{{ 'Cancel' | translate }}</ion-button>
        </ion-buttons>
        <ion-title>{{ 'Modules.Ticketing.CustomFieldsForm' | translate }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <form [formGroup]="formGroup" #form="ngForm" *ngIf="formGroup && formBuilder">
        <app-custom-fields-form 
            [customFields]="customFields"
            [fieldForm]="formGroup"
            [formBuilder]="formBuilder"
            [submitAttempted]="submitAttempted"
            [isLogged]="isLogged"
            [user]="user">
        </app-custom-fields-form>
    </form>
</ion-content>

<div class="fixed-btn py-2">
    <ion-item lines="none">
        <ion-label slot="start">
            <h3>
                {{ 'TOTAL' | translate }}
            </h3>
            <p>
                <strong>
                    {{ price | currency: 'EUR':'symbol':'1.0-2' }}
                </strong>               
            </p>
        </ion-label>
        <ion-button 
            *ngIf="formGroup"
            slot="end"
            class="inscribe-btn"
            [style]="'--background:' + corpColor + ';'"
            [class.opacity]="formGroup?.invalid"
            (click)="checkForm()">
            {{ 'CONTINUE' | translate }}
        </ion-button>
    </ion-item>
</div>