<ion-header no-border class="corp">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{'News.Detail.TITLE' | translate}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="share()">
        <ion-icon slot="icon-only" name="share-social-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" [style]="'--color-corp-1: ' + colorCorp + ';'">

  <div class="pulse-spinner" *ngIf="isLoading"></div>

  <div *ngIf="new">
    <!-- <ion-img *ngIf="new.image" [src]="new.image | imageUrl"></ion-img> -->
    
    <h2>{{ new.title }}</h2>
    
    <div class="category" *ngIf="new.categoryId">
      <span>{{ new.categoryName }}</span>
    </div>

    <div class="pub-date">{{new.publishDate | date:'shortDate'}}</div>

    <!--div class="summary" [innerHTML]="new.summary"></div-->

    <img *ngIf="new.image" [src]=" new.image | imageUrl" loading="lazy" data-fancybox="gallery-main"/>    

    <app-translate-button 
      [lang]="new.lang"
      (getTranslations)="getTranslations($event)"
      [textToTranslate]="translations"
    ></app-translate-button>

    <div class="body" [innerHTML]="new.body | safe:'html'"></div>

    <div class="mt-3" *ngIf="new.images && new.images.length > 1">
      <ion-label class="gallery-label">
        <strong>{{ 'Gallery' | translate }}</strong>
      </ion-label>
      
      <div class="gallery">      
        <img 
          loading="lazy"
          data-fancybox="gallery"
          *ngFor="let img of new.images" 
          [src]="img | imageUrl"
          [alt]="'Image'">
      </div>
    </div>

    <div *ngIf="webScrapingElementDomain" class="source">
      {{ 'SOURCE' | translate }}: <a [href]="new.webScrapingElementUrl" target="_blank">{{webScrapingElementDomain}}</a>
    </div>
    <ng-container *ngIf="new.files && new.files.length">
      <h4>{{ 'ATTACHED_FILES' | translate }}</h4>
      <app-attached-files [files]="new.files"></app-attached-files>
    </ng-container>

    <app-feedback-faces [type]="reactionType" [id]="id"></app-feedback-faces>
    
  </div>
</ion-content>
