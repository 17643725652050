export enum CommunityPaymentMethodTypes {
    STRIPE = 'STRIPE',
    REDSYS = 'REDSYS'
}

export enum PaymentStatus {
    PENDING = 'PENDING',
    PAID = 'PAID',
    ERROR = 'ERROR'
}  

export interface StripeData {
    stripePaymentIntentId: string;
    paymentIntent: string;
    ephemeralKey: string;
    customerId: string;
}

export interface RedsysData {
    fuc: string;
    terminal: string;
    orderId: string;
}

export interface PurchaseResultDTO {
    method: CommunityPaymentMethodTypes | null;
    status: PaymentStatus;
    error: any;
    purchaseUuid: string;
    stripeData?: StripeData;
    redsysData?: RedsysData;
}
