<ng-container *ngIf="model">
    <!--<h3 *ngIf="myTickets.length">{{'Modules.Ticketing.TICKETS' | translate}}</h3>-->
    <h3></h3>

    <app-element-tile *ngFor="let x of myTickets" [ticketPurchase]="x" [routerLink]="['/tickets/my-tickets/' + x.uuid]"></app-element-tile>

    <ng-container *ngIf="inTime && (maxTiquetsPerUser == null || maxTiquetsPerUser > 0) && (availableTickets == null || availableTickets > 0)">
        <ng-container *ngIf="!myTickets.length">
            <h3 *ngIf="!fullFree">{{'Modules.Ticketing.BUY_TICKETS' | translate}}</h3>
            <h3 *ngIf="fullFree">{{'Modules.Ticketing.GET_TICKETS' | translate}}</h3>
        </ng-container>
        <ng-container *ngIf="myTickets.length">
            <h3 *ngIf="!fullFree">{{'Modules.Ticketing.BUY_MORE_TICKETS' | translate}}</h3>
            <h3 *ngIf="fullFree">{{'Modules.Ticketing.GET_MORE_TICKETS' | translate}}</h3>
        </ng-container>

        <div class="prices-list">
            <div class="price-item" *ngFor="let p of model.prices">
                <div>
                    {{p.name}}
                    <p *ngIf="p.totalWithFee">
                        <span *ngIf="model.feeChargedTo == 1 || !model.showFeeToUser">{{p.totalWithFee | currency:'EUR'}}</span>
                        <span *ngIf="model.feeChargedTo == 0 && model.showFeeToUser">{{p.total | currency:'EUR'}}</span>
                    </p>
                    <p *ngIf="p.totalWithFee == 0">
                        {{'Modules.Ticketing.FREE' | translate}}
                    </p>
                </div>
                <div>
                    <app-quantity-input [(ngModel)]="p.qty" [max]="availableTickets ?? 99999 < model.maxByUser ?? 99999 ? availableTickets : model.maxByUser" (ngModelChange)="onChangeQty(p)"></app-quantity-input>
                </div>
            </div>
    
            <div class="price-item subtotal" *ngIf="model.showFeeToUser && subtotal > 0 && subtotal != totalWithFee">
                <div>Subtotal</div>
                <div>{{subtotal | currency:'EUR'}}</div>
            </div>
            <div class="price-item fee" *ngIf="model.showFeeToUser && (totalWithFee - subtotal) > 0">
                <div>{{ 'Modules.Ticketing.USER_FEE' | translate }}</div>
                <div>{{(totalWithFee - subtotal) | currency:'EUR'}}</div>
            </div>
            <div class="price-item total" *ngIf="totalWithFee > 0">
                <div><b>Total</b></div>
                <div>
                    <b>{{totalWithFee | currency:'EUR'}}</b>
                </div>
            </div>
        </div>

        <div *ngIf="maxTiquetsPerUser != null && (totalQty > maxTiquetsPerUser)" class="error-text">
            <ng-container *ngIf="model.maxByUser == 1">{{'Modules.Ticketing.ONLY_1_TICKET_PER_USER' | translate}}</ng-container>
            <ng-container *ngIf="model.maxByUser > 1">{{'Modules.Ticketing.MAX_BY_USER_MESSAGE' | translate: { value: model.maxByUser } }}</ng-container>
        </div>
        <div *ngIf="availableTickets != null && (totalQty > availableTickets || availableTickets < 10)" class="error-text">
            <ng-container *ngIf="availableTickets == 1">{{'Modules.Ticketing.ONLY_1_TICKET' | translate}}</ng-container>
            <ng-container *ngIf="availableTickets > 1">{{'Modules.Ticketing.MAX_SALES_MESSAGE' | translate: { value: availableTickets } }}</ng-container>
        </div>
        
        <div class="mt-4">
            <ion-button expand="block" shape="round" [disabled]="totalQty == 0 || (maxTiquetsPerUser != null && totalQty > maxTiquetsPerUser) || (availableTickets != null && totalQty > availableTickets)" (click)="continue()">
                <ng-container *ngIf="!isPurchaseLoading && totalWithFee > 0">
                    {{'Modules.Ticketing.BUY' | translate}} <span>&nbsp;({{totalWithFee | currency:'EUR'}})</span>
                </ng-container>
                <ng-container *ngIf="!isPurchaseLoading && totalWithFee == 0">
                    {{'Modules.Ticketing.GET' | translate}} 
                </ng-container>
                <ng-container *ngIf="isPurchaseLoading">
                    <ion-spinner name="crescent"></ion-spinner>
                </ng-container>
            </ion-button>
        </div>

    </ng-container>

    <div *ngIf="!inTime && !myTickets.length" class="purchasing-closed">
        {{'Modules.Ticketing.PURCHASING-CLOSED' | translate}}
    </div>
</ng-container>
