<div class="footer-fixed">
    <!-- TICKETS EXHAURITS -->
    <ng-container *ngIf="exceeded && !saleClosed">
        <ion-button expand="block" shape="round" class="disabled" color="medium">
            {{'Modules.Ticketing.Exceeded' | translate}}
        </ion-button>
    </ng-container>
    
    <ng-container *ngIf="!exceeded">
        <!-- COMPRA DISPONIBLE -->
        <ion-button *ngIf="inTime" expand="block" shape="round" (click)="callbackFunction(null)">
            {{'Modules.Ticketing.GET_TICKETS' | translate}}
        </ion-button>

        <!-- VENTA PENDENT D'OBRIR -->
        <ion-button *ngIf="!inTime && !saleClosed" expand="block" shape="round" class="disabled" color="medium">
            {{ 'Modules.Ticketing.Opens' | translate: { x: (dateStart | relativeTime)} }}
        </ion-button>
    </ng-container>    
    
    <!-- VENTA TANCADA -->
    <ng-container *ngIf="saleClosed">
        <ion-button expand="block" shape="round" class="disabled" color="medium">
            {{ 'Modules.Ticketing.SaleClosed' | translate: { x: (dateStart | relativeTime)} }}
        </ion-button>
    </ng-container>    
</div>
