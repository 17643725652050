import { Subscription } from "rxjs";

export class UploadedFileModel {
    id: number;
    path: string;
    filename?: string;
    type?: string;
    name: string;
    isImage?: boolean | null;
    originalName?: string;
    uploadProgress?: number | null;
    dashoffset?: number | null;
    uploadSub?: Subscription | null;
    error?: string | null;
    isDragover?: boolean | null;

    updateProgress(progress: number) {
        this.uploadProgress = Math.round(100 * progress);

        setTimeout(() => {
            this.dashoffset = (1 - progress) * (2 * (22 / 7) * 40);
        }, progress === 1 ? 200 : 0);
    }

    cancelUpload() {
        if (this.uploadSub) this.uploadSub.unsubscribe();
        this.reset();
    }

    reset() {
        this.updateProgress(0);
        this.uploadProgress = null;
        this.uploadSub = null;
    }
}