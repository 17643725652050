import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, ViewChild, type OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { API_URL } from 'src/environments/environment';
import { CommunityService } from 'src/app/services/community/community.service';
import { first } from 'rxjs/operators';
import { ApplicationPipesModule } from 'src/app/pipes/application-pipes.module';

@Component({
    selector: 'app-redsys-modal',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        ApplicationPipesModule
    ],
    templateUrl: './RedsysModal.component.html',
    styleUrls: ['./RedsysModal.component.scss'],
    //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedsysModalComponent implements OnInit, AfterViewInit {

    @ViewChild('iframeElement', { static: false }) iframeElement: ElementRef;
    
    public isLoading = true;
    public MODE = 'REDIRECTION'; // INSITE_FIELDS, INSITE_UNIFIED
    //public unified = true;
    //public API_URL = API_URL;
    public iframeUrl = null;

    //private ticketId: number;
    private purchaseUuid: string;
    private orderId: string;

    private fuc: string;
    private terminal: string;

    private redsysLoading = false;

    /*
    @HostListener('window:message', ['$event'])
    onMessage(event: { data: { idOper: any; error: any; }; }) {
        if  (event.data.idOper){
            Swal.fire({                
                icon: 'success',
                text: 'ok',
            });
            console.log(event.data.idOper);
        } else if(event.data.error){
            Swal.fire({                
                //title: this.translate.instant('Oops'),
                icon: 'warning',
                text: this.translate.instant('REDSYS.' + event.data.error),
                confirmButtonText: this.translate.instant('CONTINUE')
            });
        }
    }
    */
    
    constructor(
        private modalCtrl: ModalController,
        private translate: TranslateService,
        private communityService: CommunityService
    ) {}

    async ngOnInit(): Promise<void> {
    }

    async ngAfterViewInit(): Promise<void> {
        var c = await this.communityService.Get().pipe(first()).toPromise();
        this.iframeUrl = `${API_URL}/v2/Communities/${c.code}/Payments/Redsys/redirection?uuid=${this.purchaseUuid}&orderId=${this.orderId}`; // TODO move to generic URL (out of tickets)
        this.isLoading = false;
        
        setTimeout(() => {
            this.iframeElement.nativeElement.addEventListener('load', () => {
              try {
                const location = this.iframeElement.nativeElement.contentWindow.location;
                //console.log('iframe location:', location);
                //console.log('iframe href:', location.href);
                if (location.hostname == 'localhost'){
                    this.modalCtrl.dismiss(null, 'redsys-return');
                }
              } catch (error) {
                // Forbidden to get iFrame URL from redsys (cross-domain security)
              }
            });
        }, 0);
        
        let w: any;

        if (this.MODE != 'REDIRECTION'){
            /*
            w = (window as any);

            function merchantValidationEjemplo(){
                //Insertar validaciones…
                return true;
            }
    
            //<!-- Listener de recepción de ID de operación -->
            window.addEventListener("message", (event)  => {
                w.storeIdOper(event,"token", "errorCode", merchantValidationEjemplo);
                let idOper = event.data.idOper;
                if (idOper && idOper != '-1'){
                    if (!this.redsysLoading){
                        this.redsysLoading = true;
                        console.log(event.data.idOper);
                        debugger;
                        this.srv.purchaseByRedsysStart(0, this.purchaseUuid, this.orderId, event.data.idOper)
                        .then((res) => {
                            console.log(res);
                            this.execute3DSMethod(res);
                        });
                    }
                } else if(event.data.error){
                    Swal.fire({                
                        //title: this.translate.instant('Oops'),
                        icon: 'warning',
                        text: this.translate.instant('REDSYS.' + event.data.error),
                        confirmButtonText: this.translate.instant('CONTINUE')
                    });
                }
            });
            */
        }
        
        if (this.MODE == 'REDIRECTION'){
          
        } else if (this.MODE == 'INSITE_UNIFIED'){
            var insiteJSON = {
                "id" : "card-form",
                "fuc" : this.fuc,
                "terminal" : this.terminal,
                "order" : this.orderId,
                "estiloInsite" : "twoRows"
            }
            
            w.getInSiteFormJSON(insiteJSON);
        } else if (this.MODE == 'INSITE_FIELDS'){
            //<!-- Petición de carga de iframes -->
            w.getCardInput('card-number', "estiloCaja", "placeholder", "");
            w.getExpirationInput('card-expiration', "", "placeholder");
            w.getCVVInput('cvv', "", "placeholder");
            w.getPayButton('boton', "", 'Texto botón pago', this.fuc, this.terminal, this.orderId);
        }    
    }

    public cancel() {
        return this.modalCtrl.dismiss(null, 'cancel');
    }

    private execute3DSMethod(redsysStartResponseDTO: any){
        let threeDSMethodData = {
            threeDSServerTransID: redsysStartResponseDTO.threeDSServerTransID,
            threeDSMethodNotificationURL: 'localhost'
          }

        const IFRAME_NAME = 'threeDSMethodIframe';

        // Create IFRAME
        let iframe = document.createElement('iframe');
        iframe.classList.add('hidden');
        iframe.name = IFRAME_NAME;

        document.body.appendChild(iframe);

        // Create FORM
        const form = document.createElement('form')
        
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'threeDSMethodData';
        input.value = this.base64url(JSON.stringify(threeDSMethodData));

        form.appendChild(input);

        document.body.appendChild(form);

        form.action = redsysStartResponseDTO.threeDSMethodURL;
        form.target = IFRAME_NAME; // id of iframe
        form.method = 'POST';
        form.submit();

        //document.getElementById('threeDSMethodData').value = "valor_que_necesitas";
    }

    private base64url(source: string) {
        // Codificar en Base64 estándar
        let encoded = btoa(source);
    
        // Reemplazar caracteres para que sea URL-safe
        encoded = encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    
        return encoded;
    }

}
