import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, type OnInit, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { DateIsBetweenResult, Helpers } from 'src/app/helpers';
import { ApplicationPipesModule } from 'src/app/pipes/application-pipes.module';

@Component({
    selector: 'app-sale-button',
    standalone: true,
    imports: [
        IonicModule, 
        CommonModule,
        TranslateModule,
        ApplicationPipesModule
    ],
    templateUrl: './sale-button.component.html',
    styleUrls: ['./sale-button.component.scss'],    
})
export class SaleButtonComponent implements OnInit {
    
    @Input() exceeded: boolean;
    @Input() dateEnd: Date;
    @Input() dateStart: Date;
    @Input() callbackFunction: (args: any) => void;

    public inTime: boolean = false;
    public saleClosed: boolean = null;

    ngOnInit(): void {         
        this.inTime = Helpers.CheckIfDateIsBetween(this.dateStart.toISOString(), this.dateEnd.toISOString()) == DateIsBetweenResult.Between;        
        this.saleClosed = DateTime.now() > DateTime.fromJSDate(this.dateEnd);
    }
}
