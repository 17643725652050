import { Injectable } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { CommunityService } from "src/app/services/community/community.service";
import { API_URL } from "src/environments/environment";
import { TicketDTO } from "../DTO/TicketDTO";
import { TicketPriceDTO } from "../DTO/TicketPriceDTO";
import { TicketPurchaseDTO } from "../DTO/TicketPurchaseDTO";
import { TicketsWithCustomFieldsDTO } from "../DTO/TicketsWithCustomFieldsDTO";

@Injectable({
    providedIn: 'root'
  })
  export class TicketingService {
  
    communityCode : string;

    constructor(
        private communityService: CommunityService,
        private api: ApiService
    ) {
        this.communityService.Get().subscribe((res) => {
            this.communityCode = res.code;
        })
    }

      async get(): Promise<TicketDTO[]> {
        return this.api.get(`${API_URL}/v2/Communities/${this.communityCode}/Modules/Tickets`);
      }

      async getById(id: number): Promise<TicketDTO> {
        return this.api.get(`${API_URL}/v2/Communities/${this.communityCode}/Modules/Tickets/${id}`);
      }

      async getAvailability(id: number): Promise<number> {
        return this.api.get(`${API_URL}/v2/Communities/${this.communityCode}/Modules/Tickets/${id}/availability`);
      }

      async purchase(id: number, details: TicketsWithCustomFieldsDTO, bypass = false): Promise<any> {
        return this.api.post(`${API_URL}/v2.1/Communities/${this.communityCode}/Modules/Tickets/${id}/purchase${bypass ? '?bypass=true': ''}`, details);
      }
      
      async getMyTickets(ticketId: number = null): Promise<TicketPurchaseDTO[]> {
        return this.api.get(`${API_URL}/v2/Communities/${this.communityCode}/Modules/Tickets/my` + (ticketId ? `?ticketId=${ticketId}` : '' ));
      }

      async getMyTicket(uuid: string): Promise<TicketPurchaseDTO> {
        return this.api.get(`${API_URL}/v2/Communities/${this.communityCode}/Modules/Tickets/my/${uuid}`);
      }

      async validateTicket(uuid: string): Promise<TicketPurchaseDTO> {
        return this.api.get(`${API_URL}/v2/Communities/${this.communityCode}/Modules/Tickets/validate/${uuid}`);
      }
  }
